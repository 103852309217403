// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {ySvSB9xqx: {hover: true}};

const cycleOrder = ["ySvSB9xqx"];

const variantClassNames = {ySvSB9xqx: "framer-v-1nszkwy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "ySvSB9xqx", link: KmUICodcl, image: tBhwyasd9 = {src: new URL("assets/4096/u60aC9Qxl65KLrjh4EMMKDTERc.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/u60aC9Qxl65KLrjh4EMMKDTERc.jpg", import.meta.url).href} 100w, ${new URL("assets/1024/u60aC9Qxl65KLrjh4EMMKDTERc.jpg", import.meta.url).href} 201w, ${new URL("assets/2048/u60aC9Qxl65KLrjh4EMMKDTERc.jpg", import.meta.url).href} 402w, ${new URL("assets/4096/u60aC9Qxl65KLrjh4EMMKDTERc.jpg", import.meta.url).href} 804w, ${new URL("assets/u60aC9Qxl65KLrjh4EMMKDTERc.jpg", import.meta.url).href} 807w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ySvSB9xqx", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-9EJyW", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Link href={KmUICodcl}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 613, intrinsicWidth: 1000, pixelHeight: 613, pixelWidth: 1000, ...toResponsiveImage_194x2gw(tBhwyasd9)}} className={`${cx("framer-1nszkwy", className)} framer-1ir6f5k`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ySvSB9xqx"} ref={ref} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition} variants={{"ySvSB9xqx-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"ySvSB9xqx-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-9EJyW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9EJyW * { box-sizing: border-box; }", ".framer-9EJyW .framer-1ir6f5k { display: block; }", ".framer-9EJyW .framer-1nszkwy { height: 2850px; overflow: hidden; position: relative; text-decoration: none; width: 565px; will-change: transform; }", ".framer-9EJyW .framer-v-1nszkwy .framer-1nszkwy { cursor: pointer; }", ".framer-9EJyW.framer-v-1nszkwy.hover .framer-1nszkwy { aspect-ratio: 0.19824561403508772 / 1; height: var(--framer-aspect-ratio-supported, 2850px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2850
 * @framerIntrinsicWidth 565
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fM4o_P5rA":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"KmUICodcl":"link","tBhwyasd9":"image"}
 */
const FramerVJisjRd9C: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerVJisjRd9C;

FramerVJisjRd9C.displayName = "image 20";

FramerVJisjRd9C.defaultProps = {height: 2850, width: 565};

addPropertyControls(FramerVJisjRd9C, {KmUICodcl: {title: "Link", type: ControlType.Link}, tBhwyasd9: {__defaultAssetReference: "data:framer/asset-reference,u60aC9Qxl65KLrjh4EMMKDTERc.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerVJisjRd9C, [])